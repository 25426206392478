<template>
  <div>
    <!-- 签名弹窗 -->
    <el-dialog title="报告签名" :visible.sync="signed_Visible" width="45%" :modal="false" top="2vh">
      <div>
        <h4>请在下方签名</h4>
        <el-divider style="background: #cccc99"></el-divider>
        <vue-esign
          ref="esign"
          :width="800"
          :height="300"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
        <el-divider style="background: #cccc99"></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleReset" type="primary" size="mini">清空签名</el-button>

          <el-button @click="handleGenerate" type="success" size="mini">确认</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 密码签名 -->
    <el-dialog width="30%" title="密码签名" class="pwd-form" :visible.sync="pwdSigned_Visible">
      <div style="width: 60%" class="form-content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item
            label="签名密码"
            prop="pwd"
            :rules="[{ required: true, message: '密码不能为空' }]"
          >
            <el-input autocomplete="off" type="password" v-model="form.pwd" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="common-button commonBtn" @click="onSubmit('form')">
              确定
            </el-button>
            <el-button
              type="primary"
              class="common-button commonBtn"
              @click="pwdSigned_Visible = false"
            >
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 密码解签 -->
    <el-dialog
      width="30%"
      title="密码解签"
      class="pwd-form"
      :visible.sync="pwdRelieveSigned_Visible"
    >
      <div style="width: 60%" class="form-content">
        <el-form ref="relieveForm" :model="relieveForm" label-width="80px">
          <el-form-item
            label="解签密码"
            prop="pwd"
            :rules="[{ required: true, message: '密码不能为空' }]"
          >
            <el-input autocomplete="off" type="password" v-model="relieveForm.pwd" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRelieveSubmit('relieveForm')">确定</el-button>
            <el-button @click="pwdRelieveSigned_Visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'
import { baseUrl } from '../../../utils'

export default {
  name: 'SignReport',
  components: {
    CqtInput
  },
  props: {
    templateData: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: String,
      default: ''
    },
    operation: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    }
  },
  created() {},
  computed: {},
  watch: {},
  data() {
    return {
      signType: 'SIGN_TYPE', // 后端要求写死
      signed_Visible: false,
      // 密码弹窗
      pwdSigned_Visible: false,
      pwdRelieveSigned_Visible: false, //解签
      form: {
        pwd: ''
      },
      relieveForm: {
        pwd: ''
      },
      //报告签名参数
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '#ffffff',
      resultImg: '',
      isCrop: false,
      signed_info: {
        signpwd: ''
      },
      // 当前签名报告信息
      signed_val: {},
      loading: false,
      operationId: null
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  methods: {
    getReportSignType() {
      this.$api.get(`/v1/webconsole/sys/defaultValue?code=${this.signType}`).then((res) => {
        if (res.data.status === 200) {
          if (res.data.data == '手动签名') {
            this.signed_Visible = !this.signed_Visible
          } else if (res.data.data === '密码签名') {
            this.pwdSigned_Visible = !this.pwdSigned_Visible
          }
        }
      })
    },
    //清空画布
    handleReset() {
      this.$refs['esign'].reset()
    },
    // 手动签名确定
    handleGenerate() {
      this.$refs['esign']
        .generate()
        .then((res) => {
          this.resultImg = res // 得到了签字生成的base64图片
          // 账号密码校验
          // this.dialogFormVisible = true;
          this.signing()
        })
        .catch((err) => {
          //  没有签名，点击生成图片时调用
          this.$message({
            message: err + ' 未签名！',
            type: 'warning'
          })
          alert(err) // 画布没有签字时会执行这里 'Not Signned'
        })
    },
    // 手动签名
    signing() {
      if (!this.operation || !this.operationId) {
        return this.$message({
          message: '参数错误！',
          type: 'warning'
        })
      }
      this.loading = true
      const params = new FormData()
      params.append('signature', this.resultImg)
      let url = null
      // 护理报告
      if (this.type == 'Nursing') {
        url = `/v1/webconsole/report/signature/nursing/${this.operationId}`
      } else {
        // 手术报告
        url = `/v1/webconsole/report/signature/doctor/${this.operationId}/${this.operation}`
      }
      this.$api
        .post(url, params, baseUrl)
        .then(() => {
          this.$message.success('签名完成')
          this.$emit('signComplete', true)
          this.signed_Visible = !this.signed_Visible
          this.handleReset()
        })
        .catch(() => {
          this.loading = false
        })
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.signingpwd()
        } else {
          return false
        }
      })
    },
    // 密码签名
    signingpwd() {
      if (!this.operation || !this.operationId) {
        return this.$message({
          message: '参数错误！',
          type: 'warning'
        })
      }
      this.loading = true
      const params = new FormData()
      params.append('pwd', this.form.pwd)
      let url = null
      // 护理报告
      if (this.type == 'Nursing') {
        url = `/v1/webconsole/report/signature/nursing/${this.operationId}`
      } else {
        // 手术报告
        url = `/v1/webconsole/report/signature/doctor/${this.operationId}/${this.operation}`
      }
      this.$api
        .post(url, params, baseUrl)
        .then(() => {
          this.$message.success('签名完成')
          this.$emit('signComplete', true)
          this.pwdSigned_Visible = !this.pwdSigned_Visible
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 解签
    showRelievePwdDialog() {
      this.pwdRelieveSigned_Visible = !this.pwdRelieveSigned_Visible
    },
    onRelieveSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.signingRelievePwd()
        } else {
          return false
        }
      })
    },
    signingRelievePwd() {
      if (!this.operation || !this.operationId) {
        return this.$message({
          message: '参数错误！',
          type: 'warning'
        })
      }
      this.loading = true
      const params = new FormData()
      params.append('pwd', this.relieveForm.pwd)
      let url = null
      // 护理报告
      if (this.type == 'Nursing') {
        url = `/v1/webconsole/report/divination/nursing/${this.operationId}`
      } else {
        // 手术报告
        url = `/v1/webconsole/report/divination/doctor/${this.operationId}/${this.operation}`
      }
      this.$api
        .post(url, params, baseUrl)
        .then(() => {
          this.$message.success('解签成功！')
          this.pwdRelieveSigned_Visible = !this.pwdRelieveSigned_Visible
          this.$emit('signComplete', false)
          this.relieveForm.pwd = '' // 清空密码
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.get-undo-redo-main-content {
  margin-top: 30px;
  margin-bottom: 30px;
  .get-undo-redo-main-content-head {
    height: 50px;
    display: flex;
    align-items: center;
    .template-select {
      height: 3rem;
      line-height: 3rem;
      display: flex;
      .el-select {
        width: 300px;
      }
      .template-select-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .undo-button {
      padding-left: 10px;
    }
  }
}
</style>
