<template>
  <div>
    <div v-if="!isReadonly">
      <el-radio-group v-model="radio" @change="radioChange">
        <div v-if="options">
          <el-radio v-for="(item, index) in options" :key="index" :label="item.label">
            {{ item.label }}
          </el-radio>
        </div>
        <div v-if="!options">
          <slot></slot>
        </div>
      </el-radio-group>
    </div>
    <div v-else>
      <el-tag v-if="value" type="info">{{ value }}</el-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isReadonly: {
      type: [Boolean, Number],
      default: () => false
    },
    value: {
      type: String || Number || Boolean
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    /**
     * [
     *  {
     *      label: '',
     *      value: '',
     *  }
     * ]
     */
    options: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      radio: this.value
    }
  },
  watch: {
    value(val) {
      this.radio = val
    }
  },
  methods: {
    radioChange(val) {
      this.$emit('update:value', val)
    }
  }
}
</script>

<style lang="less" scoped>
</style>