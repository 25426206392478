<template>
  <div>
    <div v-if="!isReadonly">
      <el-input
        :value="value"
        :type="type"
        :max="max"
        :min="min"
        class="inputIn"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        :size="size"
        :prefixIcon="prefixIcon"
        :suffixIcon="suffixIcon"
        :readonly="readonly"
        :rows="rows"
        @blur="blurEvent"
        @focus="focusEvent"
        @change="changeEvent"
        @input="inputEvent"
        @clear="clearEvent"
        @keyup.enter.native="handelTab($event)"
      >
        <slot></slot>
        <template v-if="unit" slot="append">{{ unit }}</template>
      </el-input>
    </div>
    <div v-else>
      <div v-if="value">
        <div v-if="type === 'textarea'">
          <el-input :value="value" :type="type" readOnly="true" :size="size" :rows="rows">
            <template v-if="unit" slot="append">{{ unit }}</template>
          </el-input>
        </div>
        <el-tag style="margin-right: 5px" type="info" v-else-if="tag">
          {{ value }}&nbsp;&nbsp;{{ unit }}
        </el-tag>
        <span v-else>{{ value }}&nbsp;&nbsp;{{ unit }}</span>
      </div>
      <div v-else style="padding: 5px"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 1: 真； 0： 假
    // 判断是否为科研数据
    isReadonly: {
      type: [Boolean, Number],
      default: () => false
    },
    value: {
      type: [String, Number],
      default: () => null
    },
    type: {
      type: String,
      default: () => 'text'
    },
    max: {
      type: [Number, String],
      default: () => null
    },
    min: {
      type: [Number, String],
      default: () => null
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => 'mini'
    },
    prefixIcon: {
      type: String,
      default: () => null
    },
    suffixIcon: {
      type: String,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    unit: {
      type: String,
      default: () => null
    },
    rows: {
      type: Number,
      default: () => undefined
    },
    tag: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    blurEvent(e) {
      this.$emit('blur', e)
    },
    focusEvent(e) {
      this.$emit('focus', e)
    },
    changeEvent(val) {
      this.$emit('change', val)
    },
    handelTab(val) {
      this.$emit('enter', val)
    },
    inputEvent(val) {
      this.$emit('input', val)
    },
    clearEvent() {
      this.$emit('clear')
    }
  }
}
</script>

<style lang="less" scoped></style>
