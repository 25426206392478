<template>
  <div style="flex: 1">
    <div v-if="!isReadonly">
      <cm-select
        :activeUrl="activeUrl"
        :expand="expand"
        :max="max"
        :group="group"
        :config="config"
        v-model="content"
        :disabled="disabled"
        @change="selectChangeEvent"
      />
    </div>
    <div v-else>
      <el-tag style="margin-right: 5px" type="info" v-if="value">{{ value }}</el-tag>
      <div v-else style="padding: 5px"></div>
    </div>
  </div>
</template>

<script>
import CmSelect from '../CmSelect.vue'
export default {
  components: {
    CmSelect
  },
  props: {
    // 1: 真； 0： 假
    // 判断是否为科研数据
    isReadonly: {
      type: [Boolean, Number],
      default: () => false
    },
    activeUrl: {
      // 懒加载地址
      type: String,
      default: () => ''
    },
    expand: {
      // 是否可展开，true&&len<max展示成radio
      type: Boolean,
      default: true
    },
    max: {
      //
      type: Number,
      default: 2
    },
    group: {
      type: String,
      default: ''
    },
    config: {
      // 配置label，value
      type: Object,
      default: () => {
        return {
          id: 'id',
          label: 'comboValue',
          value: 'comboValue'
        }
      }
    },
    value: {
      type: [String, Number],
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.value
    }
  },
  watch: {
    value(val) {
      this.content = val
    }
  },
  methods: {
    selectChangeEvent(val) {
      if (this.timer) {
        // 如果 timer 存在，则清除上一次的定时器
        clearTimeout(this.timer)
      }

      // 设置一个新的定时器，延迟 100 毫秒执行函数体内的代码
      this.timer = setTimeout(() => {
        // 清除定时器
        this.timer = null
        // 执行函数体内的代码，这里是触发事件
        this.$emit('update:value', val)
      }, 100)
    }
  }
}
</script>

<style lang="less" scoped></style>
