<template>
  <div
    v-if="apiOptions.transfusionComponentUrl"
    class="blood-box"
    :style="
      flexWrap === 'warp'
        ? 'display:flex;flex-wrap: wrap;flex-direction: row;'
        : 'display:flex;flex-direction: column;'
    "
  >
    <div class="input-form-item">
      <div class="input-form-item-label">输血</div>
      <!-- <cqt-radio :value.sync="aorticInfo.transfusion" :isReadonly="isReadonly">
        <el-radio label="有">有</el-radio>
        <el-radio label="无">无</el-radio>
      </cqt-radio> -->
      <cqt-select
        :isReadonly="isReadonly"
        activeUrl="/v1/webconsole/comboData/get/Have_Not"
        :value.sync="aorticInfo.transfusion"
      ></cqt-select>
    </div>
    <div class="input-form-item" v-show="aorticInfo.transfusion && aorticInfo.transfusion !== '无'">
      <div class="input-form-item-label">成分</div>
      <cqt-select
        :isReadonly="isReadonly"
        activeUrl="/v1/webconsole/comboData/get/AO_composition_blood_transfusion"
        :value.sync="aorticInfo.transfusionComponent"
      ></cqt-select>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('红细胞')
      "
    >
      <div class="input-form-item-label">红细胞输血量：</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="gettransfusionAmount('redBloodCellVolume')"
          v-model="aorticInfo.redBloodCellVolume"
          size="mini"
          unit="ml"
          min="0"
          max="1000"
        >
          <template slot="append">ml</template>
        </cqt-input>
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('血小板')
      "
    >
      <div class="input-form-item-label">血小板输血量：</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="gettransfusionAmount('plateletVolume')"
          v-model="aorticInfo.plateletVolume"
          size="mini"
          unit="ml"
          min="0"
          max="1000"
        >
          <template slot="append">ml</template>
        </cqt-input>
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('血浆')
      "
    >
      <div class="input-form-item-label">血浆输血量：</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="gettransfusionAmount('plasmaVolume')"
          v-model="aorticInfo.plasmaVolume"
          size="mini"
          unit="ml"
          min="0"
          max="1000"
        >
          <template slot="append">ml</template>
        </cqt-input>
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('全血')
      "
    >
      <div class="input-form-item-label">全血输血量：</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="gettransfusionAmount('wholeBloodVolume')"
          v-model="aorticInfo.wholeBloodVolume"
          size="mini"
          unit="ml"
          min="0"
          max="1000"
        >
          <template slot="append">ml</template>
        </cqt-input>
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('其他')
      "
    >
      <div class="input-form-item-label">其他输血成分</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="aorticInfo.otherTransfusionComponent"
          size="mini"
        ></cqt-input>
      </div>
    </div>

    <div
      class="input-form-item"
      v-show="
        aorticInfo.transfusion &&
        aorticInfo.transfusion !== '无' &&
        aorticInfo.transfusionComponent?.includes('其他')
      "
    >
      <div class="input-form-item-label">其他成分输血量</div>
      <div>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="gettransfusionAmount('otherVolume')"
          v-model="aorticInfo.otherVolume"
          size="mini"
          unit="ml"
          min="0"
          max="1000"
        >
          <template slot="append">ml</template>
        </cqt-input>
      </div>
    </div>
  </div>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

export default {
  name: 'Transfusion',
  components: {
    CqtInput,
    CqtSelect,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: Object,
      default: () => {}
    },
    operation: {
      type: String,
      default: () => ''
    },
    flexWrap: {
      type: String,
      default: () => ''
    },
    apiOptions: {
      type: Object,
      default: () => {}
    }
  },
  created() {},
  computed: {},
  watch: {
    surgeryInfo: function (val) {
      if (val) {
        for (let item in val) {
          if (this.aorticInfo?.hasOwnProperty(item)) {
            this.aorticInfo[item] = val[item]
          }
        }
      }
    },
    apiOptions: {
      handler(val) {},
      immediate: true
    }
  },
  data() {
    return {
      operationId: undefined,
      aorticInfo: {
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined //其他成分血输血量,
      }
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  methods: {
    // 输血量
    gettransfusionAmount(bloodType) {
      if (this.aorticInfo[bloodType]) {
        if (this.aorticInfo[bloodType] < 0) this.aorticInfo[bloodType] = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blood-box {
  display: flex;

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
</style>
